.actions {
  padding: 1rem;
  display: flex;

  h1 {
    margin-left: 0;
    // margin-right: auto;
  }

  .back-btn {
    display: flex;
    align-items: center;
    margin: 0.5rem auto 0.5rem 1rem;
    padding: 0.8rem;
    width: fit-content;
    text-decoration: none;
    background-color: darkblue;
    border-radius: 10px;
    color: whitesmoke;

    &:hover {
      filter: brightness(0.8);
    }

    .back-btn-icon {
      margin-right: 0.5rem;
    }
  }
}

.records-table {
  margin: 0.5rem;
}

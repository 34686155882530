.schedule-form-title {
  text-align: center;
}

.snap-form {
  display: flex;
  flex-direction: column;
  padding: 3rem;

  label {
    font-size: 18px;
    margin: 1rem;

    input[type=text], input[type=datetime-local] {
      margin: 1rem;
      font-size: inherit;
      height: 40px;
      border-radius: 10px;
      padding: 0.5rem;
      border: solid 1px gray;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.4);
      }
    }
  }

  .btn-box {
    display: flex;

    input[type=submit], input[type=button] {
      margin: 1rem;
      font-size: 16px;
      text-decoration: none;
      width: 180px;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        filter: brightness(0.85);
      }
    }

    .submit {
      background-color: darkblue;
      color: whitesmoke;
    }

    .cancel {
      background-color: rgb(80,80,80);
      color: whitesmoke;
    }
  }
}

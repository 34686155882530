.topbar {
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  position: sticky;
  z-index: 9999;
  border-bottom: 1px solid lightgray;
  .topbar-wrapper {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-content {
      .logo {
        font-weight: bold;
        font-size: 30px;
        color: darkblue;
        cursor: pointer;
      }
    }
    .right-content {
      display: flex;
      align-items: center;

      .icon-container {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        color: #555555;
        .icon {
          font-size: 28px;
          display: flex;
        }
        .icon-badge {
          position: absolute;
          font-size: 13px;
          font-weight: 600;
          top: -5px;
          right: 0;
          background-color: red;
          color: white;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

@import 'src/assets/styles/variables.scss';

.sidebar {
  width: $sidebar-width;
  height: calc(100vh - $topbar-height);
  top: $topbar-height;
  position: absolute;
  border-right: 1px solid lightgray;
  .sidebar-wrapper {
    padding: 10px 20px;
    .menu {
      margin-bottom: 10px;
      .title {
        font-size: 13px;
        color: rgb(187, 186, 186);
      }
      .list {
        list-style: none;
        padding: 0 5px;

        .list-item {
          text-decoration: none;
          color: #555555;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          display: flex;
          border-radius: 10px;
          text-decoration: none;
          align-items: center;

          &.active,
          &:hover {
            background-color: rgb(228, 228, 250);
          }

          .sidebar-icon {
            margin-right: 5px;
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

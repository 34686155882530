.actions {
  padding: 1rem;

  .add-btn {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-left: auto;
    padding: 0.8rem;
    width: fit-content;
    text-decoration: none;
    background-color: darkblue;
    border-radius: 10px;
    color: whitesmoke;

    &:hover {
      filter: brightness(0.8);
    }

    .add-icon {
      margin-right: 0.5rem;
    }
  }
}

.snap-table {
  margin: 0.5rem;
}

// Source Sans Pro - Extra-light
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
}

// Source Sans Pro - Extra-light Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}

// Source Sans Pro - Light
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Light.ttf') format('truetype');
}

// Source Sans Pro - Light Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-LightItalic.ttf') format('truetype');
}

// Source Sans Pro - Regular
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}

// Source Sans Pro - Regular Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
}

// Source Sans Pro - Semi-bold
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

// Source Sans Pro - Semi-bold Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

// Source Sans Pro - Bold
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
}

// Source Sans Pro - Bold Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
}

// Source Sans Pro - Black
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-Black.ttf') format('truetype');
}

// Source Sans Pro - Black Italic
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Source Sans Pro'), url('../fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
}

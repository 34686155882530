.no-borders {
    border-bottom: none !important;
}

.MuiTableCell-head {
    font-weight: bold !important;
}

.view-btn {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    line-height: 1.75;
    box-sizing: border-box;
    padding: 10px 16px 10px 16px;
    text-decoration: none;
    background-color: darkblue;
    border-radius: 4px;
    color: whitesmoke;

    &:hover {
        filter: brightness(0.8);
    }
}

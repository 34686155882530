@import url(https://fonts.googleapis.com/css?family=Ubuntu|Courgette);
@import url(http://weloveiconfonts.com/api/?family=entypo);

$green: #2ecc71;
$blue: #3b5998;
$blue-dark: #193775;
$green-dark: #27ae60;
$facebook: $blue;
$twitter: #56b4ef;
$googleplus: #dd4b39;

[class*="entypo-"]:before {
  font-family: 'entypo', sans-serif;
}

* {
  font-family: 'Ubuntu', sans-serif;
  
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

html, body {
  width: 100%;
  height: 100%;
  
  padding: 0px;
  margin: 0px;
    
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -ms-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  background-color: rgb(248, 248, 248);
}

.logopanel {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  gap: 20px;

  .logo {
    width: 50px;
    height: 50px;
    padding: 10px 10px 10px 10px;
    background-color: $blue-dark;
  }
  .txt {
    font-weight: bold;
    color: $blue-dark;
  }
}

div.loginpanel {
  width: 100%;
  max-width: 500px;
  
  padding: 20px;
  margin: 0 auto;
  
  position: relative;
  top: 50%;
  
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  
  background-color: #FFF;
  
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  
  a.register {
    color: #444;
    text-decoration: none;
      
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
    
    &:before {
      padding-right: 6px;
    }
    
    &:hover {
      color: $blue;
      text-shadow: 
        0 1px #555;
    }
    
    &:active {
      text-shadow:
        0 -1px #555;
      -webkit-transform: translateY(2px);
      -moz-transform: translateY(2px);
      -ms-transform: translateY(2px);
      -o-transform: translateY(2px);
      transform: translateY(2px);
    }
  }
    
  input {
    width: 100%;
    margin: 8px 0px;
    padding: 12px;
  }
  
  div.txt {
    position: relative;
    
    input[type="text"], input[type="password"] {    
      color: #555;
      text-shadow: 0 1px #FFF;
      
      padding: 12px 12px 12px 45px;
      
      border: 1px solid #888;
      border: 2px solid transparent;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      outline: none;
      
      background-color: fade(#FFF, 80%);
      
      &:hover, &:focus {
        background-color: #FFF;
      }
      
      &:focus {
        border-color: $blue-dark;
      }
    }
    
    label {
      cursor: text;
      
      &:before {
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 0px;
        
        font-family: 'entypo', sans-serif;
        font-size: 1.1em;
        color: $blue;
        line-height: 58px;
        
        text-align: center;
        
        width: 50px;
        height: 50px;
      }
    }
  }
  
  div.buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    input {
      -webkit-flex: 1;
      -moz-flex: 1;
      flex: 1;
      width: 100%;
    }
    
    span {      
      a {
        display: inline-block;
        vertical-align: middle;
        padding: 0 15px;
        line-height: 70px;
        text-align: center;
      }
    }
  }
  
  input[type="button"] {
    cursor: pointer;
    
    color: #FFF;
    text-shadow: 0 1px $blue-dark;
    font-size: 1.2em;
    
    border: 0 none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    outline: none;
    
    background-color: $blue;
    
    -webkit-box-shadow: 
      0 2px $blue-dark,
      inset 0 0 $blue-dark;
    -moz-box-shadow: 
      0 2px $blue-dark,
      inset 0 0 $blue-dark;
    box-shadow: 
      0 2px $blue-dark,
      inset 0 0 $blue-dark;
    
    &:hover, &:focus {
      background-color: darken($blue, 5%);
      -webkit-box-shadow: 
        0 2px darken($blue-dark, 5%),
        inset 0 0 $blue-dark;
      -moz-box-shadow: 
        0 2px darken($blue-dark, 5%),
        inset 0 0 $blue-dark;
      box-shadow: 
        0 2px darken($blue-dark, 5%),
        inset 0 0 $blue-dark;
    }
    
    &:active {
      -webkit-box-shadow: 
        0 0 darken($blue-dark, 5%),
        inset 0 1px darken($blue-dark, 5%);
      -moz-box-shadow: 
        0 0 darken($blue-dark, 5%),
        inset 0 1px darken($blue-dark, 5%);
      box-shadow: 
        0 0 darken($blue-dark, 5%),
        inset 0 1px darken($blue-dark, 5%);
      
      -webkit-transform: translateY(2px);
      -moz-transform: translateY(2px);
      -ms-transform: translateY(2px);
      -o-transform: translateY(2px);
      transform: translateY(2px);
      
      transition: all 0s;
    }
  }
  
  div.social {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    margin-bottom: 10px;
    
    a {
      display: inline-block;
      -webkit-flex: 1;
      -moz-flex: 1;
      flex: 1;
      text-align: center;
      text-decoration: none;
      outline: none;
      
      &:before {
        display: inline-block;
        padding: 0;
        font-family: 'entypo', sans-serif;
        font-size: 2em;
        color: #444;
        line-height: 50px;
        text-align: center;
        text-shadow: 0 1px fade(#FFF, 40%);
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        
        width: 50px;
        height: 50px;
      }
      
      &:hover:before, &:focus:before {
        color: #FFF;
          
        -webkit-transition: all 0.2s ease-out, box-shadow 0.3s ease-out 0.1s;
        -moz-transition: all 0.2s ease-out, box-shadow 0.3s ease-out 0.1s;
        -ms-transition: all 0.2s ease-out, box-shadow 0.3s ease-out 0.1s;
        -o-transition: all 0.2s ease-out, box-shadow 0.3s ease-out 0.1s;
        transition: all 0.2s ease-out, box-shadow 0.3s ease-out 0.1s;
      }
      
      &:active:before {
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        
        -webkit-transition: all 0s;
        -moz-transition: all 0s;
        -ms-transition: all 0s;
        -o-transition: all 0s;
        transition: all 0s;
      }
      
      &.facebook {
        &:before {
          content: "\f30c";
        }
        
        &:hover:before, &:focus:before {
          text-shadow: 0 1px darken($facebook, 20%);
          background-color: $facebook;
          -webkit-box-shadow: 0 0 0 4px fade($facebook, 60%);
          -moz-box-shadow: 0 0 0 4px fade($facebook, 60%);
          box-shadow: 0 0 0 4px fade($facebook, 60%);
        }
        
        &:active:before {
          -webkit-box-shadow: 
            0 0 0 4px fade($facebook, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          -moz-box-shadow: 
            0 0 0 4px fade($facebook, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          box-shadow: 
            0 0 0 4px fade($facebook, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
        }
      }
      
      &.twitter {
        &:before {
          content: "\f309";
        }
        
        &:hover:before, &:focus:before {
          text-shadow: 0 1px darken($twitter, 20%);
          background-color: $twitter;
          -webkit-box-shadow: 0 0 0 4px fade($twitter, 60%);
          -moz-box-shadow: 0 0 0 4px fade($twitter, 60%);
          box-shadow: 0 0 0 4px fade(darken($twitter, 10%), 60%);
        }
        
        &:active:before {
          -webkit-box-shadow: 
            0 0 0 4px fade($twitter, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          -moz-box-shadow: 
            0 0 0 4px fade($twitter, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          box-shadow: 
            0 0 0 4px fade($twitter, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
        }
      }
      
      &.googleplus {
        &:before {
          content: "\f30f";
        }
        
        &:hover:before, &:focus:before {
          text-shadow: 0 1px darken($googleplus, 20%);
          background-color: $googleplus;
          -webkit-box-shadow: 0 0 0 4px fade($googleplus, 60%);
          -moz-box-shadow: 0 0 0 4px fade($googleplus, 60%);
          box-shadow: 0 0 0 4px fade($googleplus, 60%);
        }
        
        &:active:before {
          -webkit-box-shadow: 
            0 0 0 4px fade($googleplus, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          -moz-box-shadow: 
            0 0 0 4px fade($googleplus, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
          box-shadow: 
            0 0 0 4px fade($googleplus, 60%),
            0 0 0 4px #333,
            0 1px 0 4px fade(#FFF, 60%);
        }
      }
    }
  }
  
  div.hr {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    width: 100%;
    
    margin: 40px 0;
    
    color: #555;
    text-shadow: 0 1px fade(#FFF, 40%);
    
    div {  
      font-family: 'Courgette', cursive;
      font-size: 1.2em;
      
      &:first-child, &:last-child {
        -webkit-flex: 1;
        -moz-flex: 1;
        flex: 1;
        
        position: relative;
        
        &:before {
          content: ' ';
          position: absolute;
          top: 50%;
          left: 0px;
          right: 0px;
          
          height: 1px;
        
          background-color: #5F5F5F;
        
          -webkit-box-shadow: 0 1px 0 fade(#FFF, 20%);
          -moz-box-shadow: 0 1px 0 fade(#FFF, 20%);
          box-shadow: 0 1px 0 fade(#FFF, 20%);
        }
      }
      
      &:first-child {
        margin-right: 20px;
      }
      
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}

.resp-info:before {
  content: 'alpha';
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: #F00;
}

@media only screen and (max-width:555px){
  div.loginpanel {
    width: 90%;
    
    div.buttons {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      flex-direction: column;
      
      span {
        width: 100%;
        a {
          width: 100%;
          line-height: 40px;
        }
      }
    }
  }
  .resp-info:before {
    content: 'max 555';
  }
}
@media only screen and (max-width:400px){
  div.loginpanel {
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .resp-info:before {
    content: 'max 400';
  }
}
